var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"clearfix ui-glid-box p-10"},[_c('table',{staticClass:"table_form line-bin border-2"},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"border-2"},[_vm._m(1),_c('td',{attrs:{"colspan":"5"}},[_c('div',{staticClass:"text-xl font-semibold"},[_vm._v(_vm._s(_vm.initData.scheNm))])])]),_c('tr',{staticClass:"border-2"},[_vm._m(2),_c('td',{staticClass:"border-2"},[_c('div',{staticClass:"text-center text-xl font-semibold"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.initData.examStartDt, 'YYYY-MM-DDTHH:mm:ss', 'YYYY.MM.DD'))+" ")])]),_vm._m(3),_c('td',{staticClass:"border-2"},[_c('div',{staticClass:"text-center text-xl font-semibold"},[_vm._v(" "+_vm._s(_vm.formatTime(_vm.initData.examStartTime))+" ~ "+_vm._s(_vm.formatTime(_vm.initData.examEndTime))+" ")])]),_vm._m(4),_c('td',{staticClass:"border-2"},[_c('div',{staticClass:"text-center text-xl font-semibold"},[_vm._v(_vm._s(_vm.serverTime))])])]),_c('tr',[_c('td',{attrs:{"colspan":"6"}},[_c('div',{staticClass:"space-y-24 pt-20 pb-20"},[_vm._m(5),_c('div',{staticClass:"block text-center space-x-6"},[_c('span',{staticClass:"text-5xl font-bold text-black tracking-wide"},[_vm._v("남은시간:")]),_c('span',{staticClass:"text-5xl font-bold text-red-500 tracking-widest"},[_vm._v(_vm._s(_vm.countDownTime))])]),_c('div',{staticClass:"block text-center text-3xl font-semibold"},[_vm._v(" 응시자 "+_vm._s(_vm.initData.agtNm)+" [ "+_vm._s(_vm.initData.targetId)+" ] 잠시 기다려 주십시오."),_c('br'),_vm._v(" 자동으로 입장이 됩니다. ")]),_c('div',{staticClass:"block text-center"},[_c('DxButton',{staticClass:"btn_XS default filled txt_S medium",attrs:{"text":"닫기","width":100,"height":40},on:{"click":_vm.closeExamSheet}})],1)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{staticStyle:{"width":"100px"}}),_c('col',{staticStyle:{"width":"auto"}}),_c('col',{staticStyle:{"width":"100px"}}),_c('col',{staticStyle:{"width":"auto"}}),_c('col',{staticStyle:{"width":"100px"}}),_c('col',{staticStyle:{"width":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"border-2"},[_c('div',{staticClass:"text-center text-xl font-semibold"},[_vm._v("시험계획")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"border-2"},[_c('div',{staticClass:"text-center text-xl font-semibold"},[_vm._v("시험일")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"border-2"},[_c('div',{staticClass:"text-center text-xl font-semibold"},[_vm._v("시험시간")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"border-2"},[_c('div',{staticClass:"text-center text-xl font-semibold"},[_vm._v("현재시간")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block text-center"},[_c('img',{staticClass:"h-1/4 w-1/5",attrs:{"src":require("@/assets/images/ewm/time_check.png")}})])
}]

export { render, staticRenderFns }